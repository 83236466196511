import { Button, Form, Spin } from 'antd'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Field, getFormValues, initialize, InjectedFormProps, reduxForm } from 'redux-form'
import checkMarkImage from '../../../assets/images/check-mark.png'
import exclamationMarkImage from '../../../assets/images/exclamation-mark.png'
import BasicModal from '../../../atoms/BasicModal'
import InputField from '../../../atoms/form/InputField'
import SelectField from '../../../atoms/form/SelectField'
import StateItem from '../../../atoms/StateItem'
import UserAvatar from '../../../atoms/UserAvatar'
import { RootState } from '../../../redux'
import { companiesLoadList, ICompaniesLoadListPayload } from '../../../redux/companies/companiesActions'
import { employeesLoadDetail, employeesUpdate, IEmployeesLoadDetailPayload } from '../../../redux/employees/employeesActions'
import { userAuthDeactivate, userAuthResendInvitation } from '../../../redux/userAuth/userAuthActions'
import { ACCOUNT_STATE, FORM } from '../../../utils/enums'
import { getFilterCompanyOptions } from '../../../utils/helpers'
import validateEmployeeAccountInfoForm from './validateEmployeeAccountInfoForm'
import { IntegrationStatus, IntegrationStatuses } from '../../../utils/constants'

type IEmployeeAccountInfoForm = {}

export interface IEmployeeAccountInfoFormValues {
	firstName: string
	lastName: string
	email: string
	companyID?: number
	bankAccountNumber?: string
	integrationStatus: IntegrationStatus
}

const EmployeeAccountInfoForm: FC<InjectedFormProps<IEmployeeAccountInfoFormValues, IEmployeeAccountInfoForm> & IEmployeeAccountInfoForm> = ({
	submitting
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const employee: IEmployeesLoadDetailPayload = useSelector((state: RootState) => state.employees.detail)
	const companies: ICompaniesLoadListPayload = useSelector((state: RootState) => state.companies.list)

	const formValues = useSelector((state: RootState) => getFormValues(FORM.EMPLOYEE_ACCOUNT_INFO)(state)) as IEmployeeAccountInfoFormValues

	const integrationStatusOptions = IntegrationStatuses.map((status) => ({
		label: status,
		value: status
	}))

	const [formReadonly, setFormReadonly] = useState<boolean>(true)
	const [editConfirmModalVisible, setEditConfirmModalVisible] = useState<boolean>(false)
	const [editSuccessModalVisible, setEditSuccessModalVisible] = useState<boolean>(false)
	const [changeStateConfirmModalVisible, setChangeStateConfirmModalVisible] = useState<boolean>(false)
	const [changeStateSuccessModalVisible, setChangeStateSuccessModalVisible] = useState<boolean>(false)
	const [resendInvitationConfirmModalVisible, setResendInvitationConfirmModalVisible] = useState<boolean>(false)
	const [resendInvitationSuccessModalVisible, setResendInvitationSuccessModalVisible] = useState<boolean>(false)

	const handleFormSubmit = async () => {
		if (!employee.record) return
		await dispatch(
			employeesUpdate(
				{ ...employee.record, ...formValues },
				() => {
					setEditConfirmModalVisible(false)
					setEditSuccessModalVisible(true)
					setFormReadonly(true)
					if (!employee.record) return
					dispatch(employeesLoadDetail(employee.record.id))
				},
				false
			)
		)
	}

	const handleChangeState = () => {
		if (!employee.record) return
		setChangeStateConfirmModalVisible(false)
		dispatch(
			userAuthDeactivate(employee.record.id, () => {
				if (!employee.record) return
				dispatch(
					employeesLoadDetail(employee.record.id, () => {
						setChangeStateSuccessModalVisible(true)
					})
				)
			})
		)
	}

	const handleUserAuthResendInvitation = () => {
		if (!employee.record) return
		setResendInvitationConfirmModalVisible(false)
		dispatch(
			userAuthResendInvitation(employee.record.id, () => {
				if (!employee.record) return
				dispatch(employeesLoadDetail(employee.record.id))
				setResendInvitationSuccessModalVisible(true)
			})
		)
	}

	useEffect(() => {
		if (!employee.record) return
		dispatch(
			initialize(FORM.EMPLOYEE_ACCOUNT_INFO, {
				firstName: employee.record.firstName,
				lastName: employee.record.lastName,
				email: employee.record.email,
				companyID: employee.record.company?.id,
				bankAccountNumber: employee.record.bankAccountNumber,
				activationDate: employee.record.activationDate,
				invitationEmailSentAt: employee.record.invitationEmailSentAt,
				integrationStatus: employee.record.integrationStatus
			})
		)
	}, [employee, dispatch])

	useEffect(() => {
		dispatch(companiesLoadList())
	}, [dispatch])

	return (
		<>
			<Form noValidate={true} className={'bg-white p-4 lg:p-6 rounded-md mb-6'}>
				<h2 className={'text-20 lg:text-22 font-700 mb-6'}>{t('loc:admin|Employee Info')}</h2>
				<Spin spinning={employee.isLoading || companies.isLoading} size={'large'}>
					{employee.record && (
						<UserAvatar
							user={
								formReadonly
									? employee.record
									: {
											firstName: formValues.firstName,
											lastName: formValues.lastName
										}
							}
							showName={true}
							wrapperClassName={'mb-6'}
							avatarClassName={'w-14 h-14 text-22 lg:w-17 lg:h-17 lg:text-28'}
							nameClassName={'text-16 lg:text-20 font-600'}
						>
							<StateItem state={employee.record.accountState} />
						</UserAvatar>
					)}
					<Field
						name={'firstName'}
						component={InputField}
						label={t('loc:admin|Name')}
						placeholder={t('loc:admin|Name')}
						className={'mb-4'}
						readOnly={formReadonly}
					/>
					<Field
						name={'lastName'}
						component={InputField}
						label={t('loc:admin|Surname')}
						placeholder={t('loc:admin|Surname')}
						className={'mb-4'}
						readOnly={formReadonly}
					/>
					<Field
						name={'email'}
						component={InputField}
						label={t('loc:general|Email')}
						placeholder={t('loc:general|Email')}
						className={'mb-4'}
						readOnly={formReadonly}
					/>
					<Field
						name={'companyID'}
						component={SelectField}
						label={t('loc:admin|Company')}
						placeholder={t('loc:admin|Company')}
						options={getFilterCompanyOptions(companies.records).slice(1)}
						loading={companies.isLoading}
						disabled={!companies.isLoading && companies.isFailure}
						className={'mb-4'}
						readOnly={formReadonly}
					/>
					<Field
						name={'bankAccountNumber'}
						component={InputField}
						label={t('loc:admin|Bank Account Number (IBAN)')}
						placeholder={t('loc:admin|Bank Account Number (IBAN)')}
						className={'mb-4'}
						readOnly={formReadonly}
					/>
					<Field
						name={'integrationStatus'}
						component={SelectField}
						label={t('loc:admin|Integration Status')}
						placeholder={t('loc:admin|Integration Status')}
						options={integrationStatusOptions}
						className={'mb-4'}
						readOnly={formReadonly}
					/>
					<Field
						name={'activationDate'}
						component={InputField}
						label={t('loc:admin|Email Confirmed')}
						placeholder={t('loc:admin|Email Confirmed')}
						defaultValue={
							employee.record?.activationDate
								? dayjs(employee.record.activationDate).format('DD.MM.YYYY HH:mm')
								: t('loc:admin|This user did not complete the registration')
						}
						className={'mb-4'}
						readOnly={true}
					/>
					<Field
						name={'invitationEmailSentAt'}
						component={InputField}
						label={t('loc:admin|Invitation Email Sent At')}
						placeholder={t('loc:admin|Invitation Email Sent At')}
						defaultValue={
							employee.record?.invitationEmailSentAt
								? dayjs(employee.record.invitationEmailSentAt).format('DD.MM.YYYY HH:mm')
								: t('loc:admin|The invitation email has not been sent yet')
						}
						className={'mb-4'}
						readOnly={true}
					/>
					<div className={'flex flex-col items-center gap-y-2 lg:gap-y-4 mt-10'}>
						<Button
							type={'primary'}
							shape={'round'}
							block={true}
							loading={submitting}
							onClick={() => {
								if (formReadonly) {
									setFormReadonly(false)
								} else {
									setEditConfirmModalVisible(true)
								}
							}}
						>
							{formReadonly ? t('loc:admin|Edit Employee') : t('loc:general|Save')}
						</Button>
						{employee.record?.accountState !== ACCOUNT_STATE.DEACTIVATED && (
							<Button type={'ghost'} shape={'round'} block={true} onClick={() => setChangeStateConfirmModalVisible(true)}>
								{t('loc:admin|Deactivate Employee')}
							</Button>
						)}
						{employee.record?.accountState === ACCOUNT_STATE.DEACTIVATED && (
							<Button type={'ghost'} shape={'round'} block={true} onClick={() => setChangeStateConfirmModalVisible(true)}>
								{t('loc:admin|Reactivate employee')}
							</Button>
						)}
						{employee.record?.accountState === ACCOUNT_STATE.INACTIVE && (
							<Button type={'ghost'} shape={'round'} block={true} onClick={() => setResendInvitationConfirmModalVisible(true)}>
								{t('loc:admin|Resend Invitation')}
							</Button>
						)}
					</div>
				</Spin>
			</Form>

			<BasicModal visible={editConfirmModalVisible} onClose={() => setEditConfirmModalVisible(false)} width={'30rem'}>
				<img src={exclamationMarkImage} width={36} alt={''} className={'self-center mb-6'} />
				<p className={'text-center text-15 font-400 mb-6'}>
					{`${t('loc:admin|Are you sure you want to')} `}
					<b>{t('loc:admin|save changes')}</b> {t('loc:admin|for')}
					<br className={'hidden lg:inline'} />
					{` ${employee.record?.firstName} ${employee.record?.lastName}’s ${t('loc:admin|account')}?`}
				</p>
				<Button type={'primary'} shape={'round'} className={'mb-4'} onClick={handleFormSubmit}>
					{t('loc:admin|Yes, save changes')}
				</Button>
				<Button type={'text'} className={'mb-4'} onClick={() => setEditConfirmModalVisible(false)}>
					{t('loc:general|Cancel')}
				</Button>
			</BasicModal>

			<BasicModal visible={editSuccessModalVisible} onClose={() => setEditSuccessModalVisible(false)} width={'30rem'}>
				<img src={checkMarkImage} width={100} alt={''} className={'self-center mb-6'} />
				<h4 className={'text-center text-28 font-700 leading-sm mb-6'}>{t('loc:admin|Changes saved')}</h4>
				<Button type={'primary'} shape={'round'} onClick={() => setEditSuccessModalVisible(false)}>
					{t('loc:general|Close')}
				</Button>
			</BasicModal>

			<BasicModal visible={changeStateConfirmModalVisible} onClose={() => setChangeStateConfirmModalVisible(false)} width={'30rem'}>
				<img src={exclamationMarkImage} width={36} alt={''} className={'self-center mb-6'} />
				<p className={'text-center text-15 font-400 mb-6'}>
					{employee.record?.accountState !== ACCOUNT_STATE.DEACTIVATED && !employee.record?.activationDate && (
						<div style={{ marginBottom: '1rem' }}>
							<b>{`${t('loc:admin|Warning')}: `} </b> {t('loc:admin|This user did not complete the registration')}
						</div>
					)}
					{`${t('loc:admin|Are you sure you want to')} `}
					<b>{employee.record?.accountState !== ACCOUNT_STATE.DEACTIVATED && t('loc:admin|deactivate')}</b>
					<b>{employee.record?.accountState === ACCOUNT_STATE.DEACTIVATED && t('loc:admin|reactivate')}</b>
					<br className={'hidden lg:inline'} />
					{`${employee.record?.firstName} ${employee.record?.lastName}’s ${t('loc:admin|account')}?`}
				</p>
				<Button type={'primary'} shape={'round'} className={'mb-4'} onClick={handleChangeState}>
					{employee.record?.accountState !== ACCOUNT_STATE.DEACTIVATED && t('loc:admin|Yes, deactivate')}
					{employee.record?.accountState === ACCOUNT_STATE.DEACTIVATED && t('loc:admin|Yes, reactivate')}
				</Button>
				<Button type={'text'} className={'mb-4'} onClick={() => setChangeStateConfirmModalVisible(false)}>
					{t('loc:general|Cancel')}
				</Button>
			</BasicModal>

			<BasicModal visible={changeStateSuccessModalVisible} onClose={() => setChangeStateSuccessModalVisible(false)} width={'30rem'}>
				<img src={checkMarkImage} width={100} alt={''} className={'self-center mb-6'} />
				<h4 className={'text-center text-28 font-700 leading-sm mb-6'}>
					{employee.record?.accountState === ACCOUNT_STATE.ACTIVE && t('loc:admin|Account Reactivated')}
					{employee.record?.accountState === ACCOUNT_STATE.DEACTIVATED && t('loc:admin|Account Deactivated')}
				</h4>
				<Button type={'primary'} shape={'round'} onClick={() => setChangeStateSuccessModalVisible(false)}>
					{t('loc:general|Close')}
				</Button>
			</BasicModal>

			<BasicModal visible={resendInvitationConfirmModalVisible} onClose={() => setResendInvitationConfirmModalVisible(false)} width={'30rem'}>
				<img src={exclamationMarkImage} width={36} alt={''} className={'self-center mb-6'} />
				<p className={'text-center text-15 font-400 mb-6'}>
					{t('loc:admin|Are you sure you want to')}
					<b> {t('loc:admin|resend the invitation')}</b> {t('loc:admin|for')}
					<br className={'hidden lg:inline'} /> {employee.record?.firstName} {employee.record?.lastName}’s {t('loc:admin|account')}?
					<br />
					<br />
					{t('loc:admin|Last invitation sent at')}: &nbsp;
					{employee.record?.invitationEmailSentAt ? dayjs(employee.record.invitationEmailSentAt).format('DD.MM.YYYY HH:mm') : t('loc:admin|Not sent yet')}
				</p>
				<Button type={'primary'} shape={'round'} className={'mb-4'} onClick={handleUserAuthResendInvitation}>
					{t('loc:admin|Yes, resend invitation')}
				</Button>
				<Button type={'text'} className={'mb-4'} onClick={() => setResendInvitationConfirmModalVisible(false)}>
					{t('loc:general|Cancel')}
				</Button>
			</BasicModal>

			<BasicModal visible={resendInvitationSuccessModalVisible} onClose={() => setResendInvitationSuccessModalVisible(false)} width={'30rem'}>
				<img src={checkMarkImage} width={100} alt={''} className={'self-center mb-6'} />
				<h4 className={'text-center text-28 font-700 leading-sm mb-6'}>{t('loc:admin|Invitation Resent')}</h4>
				<Button type={'primary'} shape={'round'} onClick={() => setResendInvitationSuccessModalVisible(false)}>
					{t('loc:general|Close')}
				</Button>
			</BasicModal>
		</>
	)
}

export default reduxForm<IEmployeeAccountInfoFormValues, IEmployeeAccountInfoForm>({
	form: FORM.EMPLOYEE_ACCOUNT_INFO,
	validate: validateEmployeeAccountInfoForm,
	touchOnChange: true,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true
})(EmployeeAccountInfoForm)
