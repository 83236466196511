import { notification } from 'antd'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { chain, forEach, includes, isEmpty, lowerCase, map } from 'lodash'
import { ReactComponent as ErrorIcon } from '../assets/icons/error.svg'
import { ReactComponent as InfoIcon } from '../assets/icons/info.svg'
import { ReactComponent as SuccessIcon } from '../assets/icons/success.svg'
import { ReactComponent as WarningIcon } from '../assets/icons/warning.svg'
import FlagSlovakia from '../assets/icons/flag-slovakia-rectangle.svg'
import FlagCzechia from '../assets/icons/flag-czechia-rectangle.svg'
import StateItem from '../atoms/StateItem'
import { ICompany, IErrorMessage } from '../types/interfaces'
import { ACCOUNT_STATE, FORM, NOTIFICATION_TYPE, TABLE_ORDER, USER_ROLE, WITHDRAWAL_STATE } from './enums'

export const isAdminRoute = window.location.pathname.startsWith('/admin')

export const showNotifications = (messages: IErrorMessage[], typeNotification: NOTIFICATION_TYPE) => {
	if (!isEmpty(messages) && typeNotification === NOTIFICATION_TYPE.NOTIFICATION) {
		forEach(messages, ({ type, message }) => {
			switch (lowerCase(type)) {
				case 'error':
					return notification.error({
						icon: <ErrorIcon />,
						message: <span className={'text-error-medium'}>{i18next.t('loc:general|Error') as string}</span>,
						description: message
					})
				case 'warning':
					return notification.warning({
						icon: <WarningIcon />,
						message: <span className={'text-warning-medium'}>{i18next.t('loc:general|Warning') as string}</span>,
						description: message
					})
				case 'success':
					return notification.success({
						icon: <SuccessIcon />,
						message: <span className={'text-success-medium'}>{i18next.t('loc:general|Success') as string}</span>,
						description: message
					})
				case 'info':
				default:
					return notification.info({
						icon: <InfoIcon />,
						message: <span className={'text-purple-base'}>{i18next.t('loc:general|Info') as string}</span>,
						description: message
					})
			}
		})
	}
}

export const formFieldID = (form?: FORM | string, name?: string) => {
	// NOTE: element can't be queried if id contains dots
	if (form && name) {
		const fieldSelector = chain(name)
			.filter((char) => char !== ']')
			.map((char) => (char === '[' || char === '.' ? '-' : char))
			.value()
			.join('')
		return `${form}-${fieldSelector}`
	}
	return undefined
}

export const mapUserRoleIDToEnum = (value: unknown) => {
	switch (value) {
		case 2:
			return USER_ROLE.ADMIN
		case 1:
		default:
			return USER_ROLE.EMPLOYEE
	}
}
export const mapUserRoleEnumToID = (value: unknown) => {
	switch (value) {
		case USER_ROLE.ADMIN:
			return 2
		case USER_ROLE.EMPLOYEE:
		default:
			return 1
	}
}

export const mapOrderByToParams = (value: unknown) => {
	let orderBy
	let orderDirection
	switch (value) {
		case TABLE_ORDER.EMPLOYEE_ASC:
			orderBy = 'name'
			orderDirection = 'asc'
			break
		case TABLE_ORDER.EMPLOYEE_DESC:
			orderBy = 'name'
			orderDirection = 'desc'
			break
		case TABLE_ORDER.COMPANY_ASC:
			orderBy = 'companyName'
			orderDirection = 'asc'
			break
		case TABLE_ORDER.COMPANY_DESC:
			orderBy = 'companyName'
			orderDirection = 'desc'
			break
		case TABLE_ORDER.RECEIVED_ASC:
			orderBy = 'createdAt'
			orderDirection = 'asc'
			break
		case TABLE_ORDER.RECEIVED_DESC:
			orderBy = 'createdAt'
			orderDirection = 'desc'
			break
		default:
			break
	}
	return { orderBy, orderDirection }
}

export const setAccountState = (value: unknown) => {
	return value && Object.values(ACCOUNT_STATE).includes(value as ACCOUNT_STATE) ? ACCOUNT_STATE[value as ACCOUNT_STATE] : ACCOUNT_STATE.INACTIVE
}

export const setWithdrawalState = (value: unknown) => {
	return value && Object.values(WITHDRAWAL_STATE).includes(value as WITHDRAWAL_STATE) ? WITHDRAWAL_STATE[value as WITHDRAWAL_STATE] : null
}

export const setDate = (value: unknown) => {
	return value && dayjs(String(value)).isValid() ? dayjs(String(value)) : undefined
}

export const formatAsCurrency = (value?: number) => {
	return `${String((value || 0).toFixed(2)).replace('.', ',')} €`
}

export const getFilterWithdrawalStateOptions = () => {
	return [
		{
			label: <StateItem state={null} nullStateLabel={i18next.t('loc:admin|All withdrawals')} />,
			value: null
		},
		...map(WITHDRAWAL_STATE, (state) => ({
			label: <StateItem state={state} />,
			value: state
		}))
	]
}

export const getFilterCompanyOptions = (companies: ICompany[] | undefined) => {
	return [
		{
			label: i18next.t('loc:admin|All companies'),
			value: null
		},
		...map(companies, (company) => ({
			label: company.name,
			value: company.id
		}))
	]
}

export const getFilterOrderOptions = () => {
	return [
		{
			label: `${i18next.t('loc:admin|Default order')}`,
			value: null
		},
		{
			label: `${i18next.t('loc:admin|Received')}: ${i18next.t('loc:admin|Newest first')}`,
			value: TABLE_ORDER.RECEIVED_DESC
		},
		{
			label: `${i18next.t('loc:admin|Received')}: ${i18next.t('loc:admin|Oldest first')}`,
			value: TABLE_ORDER.RECEIVED_ASC
		},
		{
			label: `${i18next.t('loc:admin|Name')}: ${i18next.t('loc:admin|A to Z')}`,
			value: TABLE_ORDER.EMPLOYEE_ASC
		},
		{
			label: `${i18next.t('loc:admin|Name')}: ${i18next.t('loc:admin|Z to A')}`,
			value: TABLE_ORDER.EMPLOYEE_DESC
		},
		{
			label: `${i18next.t('loc:admin|Company')}: ${i18next.t('loc:admin|A to Z')}`,
			value: TABLE_ORDER.COMPANY_ASC
		},
		{
			label: `${i18next.t('loc:admin|Company')}: ${i18next.t('loc:admin|Z to A')}`,
			value: TABLE_ORDER.COMPANY_DESC
		}
	]
}

export const TELEPHONE_PREFIXES = {
	SK: {
		name: 'SK',
		flag: FlagSlovakia,
		prefix: '+421'
	},
	CZ: {
		name: 'CZ',
		flag: FlagCzechia,
		prefix: '+420'
	}
}

export const hiddenRequestError = (responseURL: string) => {
	return !includes(responseURL, 'onboarding-form')
}

export const isIOS = () => {
	return /iPad|iPhone|iPod/.test(navigator.userAgent)
}

export const isMacOS = () => {
	return /Macintosh/.test(navigator.userAgent)
}

export const isInStandaloneMode = () => {
	return 'standalone' in (window as any).navigator && (window as any).navigator.standalone
}
