export const REFRESH_PAGE_INTERVAL = 1000 * 60 * 60 * 4 // 4 hours
export const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 12 // 12 minutes

export enum APP_LANGUAGES {
	EN = 'en',
	SK = 'sk'
}

export const DEFAULT_APP_LANGUAGE = APP_LANGUAGES.EN

export enum NAMESPACE {
	LOC = 'loc',
	PATHS = 'paths'
}

export enum MSG_TYPE {
	INFO = 'INFO',
	ERROR = 'ERROR',
	WARNING = 'WARNING',
	SUCCESS = 'SUCCESS'
}

export enum NOTIFICATION_TYPE {
	MODAL = 'MODAL',
	NOTIFICATION = 'NOTIFICATION'
}

export enum FORM {
	LOGIN = 'LOGIN',
	FORGOT_PASSWORD = 'FORGOT_PASSWORD',
	RESET_PASSWORD = 'RESET_PASSWORD',
	ADMIN_CREATE = 'ADMIN_CREATE',
	EMPLOYEE_CREATE = 'EMPLOYEE_CREATE',
	EMPLOYEE_ACCOUNT_INFO = 'EMPLOYEE_ACCOUNT_INFO',
	EMPLOYEE_WITHDRAWAL_INFO = 'EMPLOYEE_WITHDRAWAL_INFO',
	WITHDRAWAL_CREATE = 'WITHDRAWAL_CREATE',
	ONBOARDING = 'ONBOARDING_WELCOME'
}

export enum TABLE_ORDER {
	EMPLOYEE_ASC = 'EMPLOYEE_ASC',
	EMPLOYEE_DESC = 'EMPLOYEE_DESC',
	COMPANY_ASC = 'COMPANY_ASC',
	COMPANY_DESC = 'COMPANY_DESC',
	RECEIVED_ASC = 'RECEIVED_ASC',
	RECEIVED_DESC = 'RECEIVED_DESC'
}

export enum USER_ROLE {
	ADMIN = 'ADMIN',
	EMPLOYEE = 'EMPLOYEE'
}

export enum USERS_CONTRACT_STATUS {
	NONE = 'NONE',
	DRAFT = 'DRAFT',
	SENT = 'SENT',
	EXPIRED = 'EXPIRED',
	COMPLETED = 'COMPLETED',
	VIEWED = 'VIEWED'
}

export enum ACCOUNT_STATE {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	DEACTIVATED = 'DEACTIVATED'
}

export enum WITHDRAWAL_STATE {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED'
}

export enum DASHBOARD_FIELDS {
	TEXT_AREA_1 = 'TEXT_AREA_1',
	TEXT_AREA_2 = 'TEXT_AREA_2',
	TEXT_AREA_3 = 'TEXT_AREA_3'
}
